import { graphql } from 'gatsby'
import React from 'react'
import { useTranslation } from 'react-i18next'
import Link from '../components/Link'
import Seo from '../components/Seo'
import TableOfContents from '../components/TableOfContents'
import { PAGE_VIEWED } from '../constants/metrics'
import DocumentationLayout from '../layouts/DocumentationLayout'
import MetricsService from '../services/metricsService'
import {
  Accordion,
  AccordionContent,
  AccordionGroup,
  AccordionHeader,
} from '@momentum-ui/react'

interface IFAQProps {
  data: {
    strapiFaq: {
      data: any
    }
  }
}

interface IFAQQuestionProps {
  question: string
  answer: string
  table?: {
    headers: Array<string>
    rows: Array<Array<string>>
  }
}

export const query = graphql`
  query FaqQuery {
    strapiFaq {
      data
    }
  }
`

const FAQ: React.FC<IFAQProps> = ({ data }): JSX.Element => {
  const { sections } = data.strapiFaq.data
  const sectionsArray = Object.keys(sections)
  const metricsService = MetricsService.getInstance()
  const { t } = useTranslation()
  const pageName = 'FAQ'

  metricsService.track(PAGE_VIEWED, {
    pageName: pageName,
  })

  const tableOfContentsItems = sectionsArray.map((section: string) => {
    return {
      label: t(`faq.${section}`),
      level: '2',
      href: `${section}`,
    }
  })

  const emphasizeText = (text: string | Array<any>): any => {
    if (Array.isArray(text)) {
      return text.map((entity: any) => {
        if (typeof entity === 'string') {
          return emphasizeText(entity)
        } else {
          return entity
        }
      })
    } else if (typeof text === 'string') {
      const patternBold = new RegExp(/(\*[\w\s]+\*)/g)
      if (text.match(patternBold)) {
        const boldList: any = text.split(patternBold)
        boldList.forEach((entry: any, index: number) => {
          if (entry.match(patternBold)) {
            boldList[index] = (
              <span key={`bold-text-${index}`} className="bold-text">
                {entry.replace(/\*/g, '')}
              </span>
            )
          }
        })
        return boldList
      }
      return text
    }
  }

  const hasLink = (text: string, key: number): any => {
    const patternLink = new RegExp(
      /((?:(?:[A-Za-z]{3,9}:\/\/?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)(?:\/[\+~%\/.\w\-_]*)?\??(?:[-\+=&;%@.\w_']*)#?(?:[-.\!\/\\\w]*)*)/g
    )
    if (patternLink.test(text)) {
      const textArray: any = text.split(patternLink)
      const links = textArray.filter((splitText: any) =>
        splitText.match(patternLink)
      )
      textArray.forEach((link: string, index: number) => {
        if (links.includes(link)) {
          const toLink = link.includes('@') ? 'mailto:' + link : link
          textArray[index] = (
            <Link
              key={`link-${index}`}
              to={toLink}
              target="_blank"
              rel="noopener noreferrer"
              className="default-link"
            >
              {link}
            </Link>
          )
        }
      })
      return <p key={`ans-with-link-${key}`}>{emphasizeText(textArray)}</p>
    }
    return <p key={`ans-paragraph-${key}`}>{emphasizeText(text)}</p>
  }

  const formatText = (text: string): any => {
    const paragraphArray: any = text.split('\n')
    const list = paragraphArray.filter((element: string) => element[0] === '-')
    const listStart = paragraphArray.indexOf(list[0])
    paragraphArray.splice(listStart, list.length, list)
    return (
      <div>
        {paragraphArray.map(
          (paragraph: string | Array<string>, index: number): any => {
            if (Array.isArray(paragraph)) {
              return (
                <ul key={index}>
                  {paragraph.map((entry: string, index: number) => (
                    <li key={index}>{entry.substring(1)}</li>
                  ))}
                </ul>
              )
            } else {
              return hasLink(paragraph, index)
            }
          }
        )}
      </div>
    )
  }

  const formatTable = (tableObject: {
    headers: Array<string>
    rows: Array<Array<string>>
  }): any => {
    return (
      <table>
        <tbody>
          <tr>
            {tableObject.headers.map((header: string, index: number) => {
              return <th key={`table-header-${index}`}>{header}</th>
            })}
          </tr>
          {tableObject.rows.map((row: Array<string>, rowIndex: number) => {
            return (
              <tr key={`table-row-${rowIndex}`}>
                {row.map((data: string, index: number) => {
                  return (
                    <td key={`row-${rowIndex}-data-${index}`}>
                      {hasLink(data, index)}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    )
  }

  const addQuestion = (pair: IFAQQuestionProps, key: number): any => {
    return (
      <Accordion
        multipleVisible
        initialActive={[0]}
        initialActiveFocus={false}
        className={`question-container`}
        key={key}
      >
        <AccordionGroup>
          <AccordionHeader className={`accordion-header question-header`}>
            <div className="">
              <h3> {pair.question} </h3>
            </div>
          </AccordionHeader>
          <AccordionContent className={`answer-container`}>
            {formatText(pair.answer)}
            {pair.table ? formatTable(pair.table) : <></>}
          </AccordionContent>
        </AccordionGroup>
      </Accordion>
    )
  }

  return (
    <DocumentationLayout>
      <Seo title={pageName} />
      <div className="guide-container with-toc faq-container">
        <article className="content">
          <section>
            <h1>{t('faq.title')}</h1>
          </section>

          <section>
            {sectionsArray.map((section: string, index: number): any => {
              return (
                <Accordion
                  multipleVisible
                  initialActive={[0]}
                  initialActiveFocus={false}
                  key={index}
                >
                  <AccordionGroup>
                    <AccordionHeader className={`accordion-header`}>
                      <div className="">
                        <h2 id={section}> {sections[section].label} </h2>
                      </div>
                    </AccordionHeader>
                    <AccordionContent className={``}>
                      {sections[section].questions.map(
                        (pair: IFAQQuestionProps, index: number) => {
                          return addQuestion(pair, index)
                        }
                      )}
                    </AccordionContent>
                  </AccordionGroup>
                </Accordion>
              )
            })}
          </section>
        </article>
        <div className="side-panel">
          <TableOfContents tableOfContentsList={tableOfContentsItems} />
        </div>
      </div>
    </DocumentationLayout>
  )
}

export default FAQ
